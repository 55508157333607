import React from "react"
import { graphql } from "gatsby"
import { Banner } from "../HeroBanner-types"
import "./login-hero.module.scss"
import { getBackgroundImageUrlByBreakpoint } from "assets/helper/getBackgroundImageUrlByBreakpoint"
import { useColorAreaSettings } from "hooks/useColorAreaSettings"
import { LoginForm } from "components/login/LoginForm"

interface LoginBannerProps {
  banner: Banner
}

export const LoginBanner = ({ banner }: LoginBannerProps) => {
  const { background_image, heading, sub_heading } = banner
  const { imageBannerColorOverlay } = useColorAreaSettings()
  const backgroundImageCSSVariable = background_image?.value[0]?.url
    ? getBackgroundImageUrlByBreakpoint({
        variableName: "hero-background-image",
        imageUrl: background_image.value[0].url,
        breakpoints: {
          mobile: {
            width: 768,
          },
        },
      })
    : {}
  const overrideBackground = imageBannerColorOverlay
    ? `${imageBannerColorOverlay}--backgroundColor`
    : ""

  return (
    <section
      className={`login-banner login-banner--patterned-background ${overrideBackground}`}
      style={backgroundImageCSSVariable}
    >
      <div className="login-banner-form-content--boxed fr-container">
        <LoginForm
          heading={heading?.value}
          subHeading={sub_heading?.value.replace(/<[^>]*>?/gm, "")}
        />
      </div>
    </section>
  )
}

export const query = graphql`
  fragment LoginBannerFragment on kontent_item_hero_banner {
    id
    elements {
      heading {
        value
      }
      sub_heading {
        value
        links {
          link_id
          url_slug
        }
      }
      background_image {
        value {
          ...KenticoAssetElementFragment
        }
      }
    }
  }
`
