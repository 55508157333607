import React, { ChangeEvent, useState, useEffect } from "react"
import { appendScript } from "assets/helper/appendScript"
import "./login.module.scss"
import { ExternalLink } from "components/external-link/ExternalLink"

interface LoginFormProps {
  heading?: string
  subHeading?: string
  variation?: "boxed" | "split-screen"
}

const FORM_CONTAINER_CLASS_MODIFIER_BY_VARIATION: Record<string, string> = {
  boxed: "login-form-container--boxed",
  "split-screen": "login-form-container--split-screen",
}

const FORM_CLASS_MODIFIER_BY_VARIATION: Record<string, string> = {
  boxed: "login-form--boxed",
  "split-screen": "login-form--split-screen",
}

export const LoginForm = ({
  heading,
  subHeading,
  variation = "boxed",
}: LoginFormProps) => {
  const [user, setUser] = useState("")
  const [password, setPassword] = useState("")
  const [logginMessage, setMessage] = useState("")
  const [subDomain, setSubDomain] = useState("")
  const [isRender, setIsRender] = useState(false)

  /* eslint-disable */
  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    // @ts-ignore: PestportalsClient was delayed imported
    let auth = PestportalsClient.getAuthenticatedPortalLink(user, password)
    auth.then((response: any) => {
      if (response.success) {
        document.location.href = response.loginLink
      } else {
        setMessage(response.clientMessage)
      }
    })
  }
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.name === "acc_num"
      ? setUser(e.target.value)
      : setPassword(e.target.value)
  }
  const script = () => {
    !(window as any).jQuery &&
      appendScript({
        id: "jquery-3.6.0.min.js",
        scriptToAppend: "https://code.jquery.com/jquery-3.6.0.min.js",
        isAsync: false,
      })
    // @ts-ignore: PestportalsClient was delayed imported
    if ((window as any).jQuery && typeof PestportalsClient !== "object")
      appendScript({
        id: "pestportalsClient.js",
        scriptToAppend:
          "https://lmk.pestroutes.com/resources/js/lobster/pestportalsClient.js",
        isAsync: true,
      })
    else setTimeout(script, 200)
  }

  useEffect(() => {
    if (isRender) {
      script()
      fetch("https://lmk.pestroutes.com/lobster/getConnectedOffices")
        .then(response => response.json())
        .then(r => {
          setSubDomain(Object.keys(r.connectedOffices.dbOffices)[0])
        })
    } else {
      setIsRender(true)
    }
  }, [isRender])

  return (
    <section
      className={`login-form-container ${FORM_CONTAINER_CLASS_MODIFIER_BY_VARIATION[variation]}`}
    >
      <div
        className={`login-form ${FORM_CLASS_MODIFIER_BY_VARIATION[variation]}`}
      >
        {heading && <div className="login-form__title">{heading}</div>}
        {subHeading && subHeading !== "<p><br></p>" && (
          <div className="login-form__sub-title">{subHeading}</div>
        )}
        <div className="form-container">
          <p className="login-form__input-label">
            Account Number: <span>*</span>
          </p>
          <label className="login-form__input-container login-form__input-container--account">
            <input
              className="input"
              type="text"
              name="acc_num"
              value={user}
              onChange={handleChange}
            />
          </label>
          <p className="login-form__input-label">
            Password: <span>*</span>
          </p>
          <label className="login-form__input-container">
            <input
              className="input"
              type="password"
              name="pass"
              value={password}
              onChange={handleChange}
            />
          </label>
        </div>

        {logginMessage && (
          <span className="login-form__error-message">
            <p>{logginMessage}</p>
          </span>
        )}
        <button
          role="button"
          className="button--solid-primary login-form__btn"
          onClick={handleSubmit}
        >
          Login
        </button>
        <p className="login-form__recover-password">
          Forgot your password?{" "}
          <ExternalLink
            href={`https://${subDomain}.pestportals.com/forgotPassword`}
          >
            Get your login info
          </ExternalLink>
        </p>
      </div>
    </section>
  )
}
